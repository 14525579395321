export function appearOnScroll (selector = '.fade-in') {

    const faders = document.querySelectorAll(selector);

    const options: IntersectionObserverInit = {
        threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries, observer) => {

        entries.forEach(entry => {

            if (!entry.isIntersecting) return;

            entry.target.classList.add('appear');
            observer.unobserve(entry.target);
        });

    }, options);

    faders.forEach((fader) => observer.observe(fader));
}
