import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

const template = function (this: FooterElement) {
    return html`
    <footer>
      <section class="boast">
        <h3>We Fixed it for you.</h3>
        <p>
          Illuminate aggregates rates from DeFi's leading protocols. Lend at the
          highest fixed rate every time.
        </p>
        <a href="https://discord.gg/illuminatefi" class="button-cta">Join our Community</a>
      </section>
      <section class="links">
        <div class="summary">
          <h1 class="logo">
            <a href="/" aria-label="illuminate homepage">
              <sw-icon name="illuminate-wordmark"></sw-icon>
            </a>
          </h1>
          <div class="socials">
            <a target="_blank" href="https://discord.gg/illuminatefi" class="discord" aria-label="illuminate on discord"></a>
            <a target="_blank" href="https://twitter.com/IlluminateFi" class="twitter" aria-label="illuminate on twitter"></a>
          </div>
        </div>
        <div class="links">
          <div class="info">
            <ul>
              <li>
                <a href="./team.html">About Us</a>
              </li>
              <li>
                <a target="_blank" href="https://discord.gg/illuminatefi">Discord</a>
              </li>
              <li>
                <a target="_blank" href="https://docs.illuminate.finance/">Docs</a>
              </li>
              <li>
                <a target="_blank" href="https://docs.illuminate.finance/media-kit">Media Kit</a>
              </li>
            </ul>
          </div>
          <div class="legal">
            <ul>
              <li>
                <a href="/cookie-policy.html">Cookie Policy</a>
              </li>
              <li>
                <a href="/privacy-policy.html">Privacy policy</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section class="copyright">
        © ${ this.year ?? 2023 } Illuminate. All rights reserved.
      </section>
    </footer>
  `;
};

@customElement('sw-footer')
export class FooterElement extends LitElement {

    protected year = new Date().getFullYear();

    createRenderRoot () {
        return this;
    }

    render () {
        return template.apply(this);
    }
}
