import "./assets/js/tilt.js";
import "./components/carousel";
import "./components/footer";
import "./components/animation-hero-scrolling.js";
import "./components/icon";
import "./components/nav";
import "./components/tiltElement";
import { appearOnScroll } from "./scripts/appear-on-scroll";
import { consent } from "./scripts/consent";
import { parallaxBackground } from "./scripts/parallax-background";

appearOnScroll();
parallaxBackground();

consent.start();
