export function parallaxBackground (property = '--scroll-offset') {

    const root = document.querySelector(':root') as HTMLElement;

    let scheduled = false;

    document.addEventListener('scroll', () => {

        if (!scheduled) {

            requestAnimationFrame(() => {

                const scrollOffset = window.scrollY;

                root.style.setProperty(property, `${ scrollOffset }px`);

                scheduled = false;
            });

            scheduled = true;
        }
    });
}
