import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { APP_URL } from '../constants';

const template = function (this: NavElement) {
    return html`
    <nav>
      <h1 class="logo">
        <a href="/" aria-label="illuminate homepage">
          <sw-icon name="illuminate-wordmark"></sw-icon>
        </a>
      </h1>
      <div class="center-nav">
        <a
          href="https://docs.illuminate.finance/"
          target="_blank"
          rel="noopener"
        >
          Docs
        </a>
        <a
        href="https://twitter.com/IlluminateFi"
        target="_blank"
        rel="noopener"
        aria-label="Twitter"
        title="Twitter"
        >
        Twitter
        </a>
        <a
          href="https://discord.gg/illuminatefi"
          target="_blank"
          rel="noopener"
          aria-label="Discord"
          title="Discord"
        >
          Discord
        </a>
      </div>
      <a
        href="${ APP_URL }"
        class="button-cta"
        target="_blank"
        rel="noopener"
      >
        Launch App
      </a>
    </nav>
  `;
};

@customElement('sw-nav')
export class NavElement extends LitElement {

    createRenderRoot () {
        return this;
    }

    render () {
        return template.apply(this);
    }
}
