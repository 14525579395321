import { html, LitElement } from "lit";
import { customElement, query, queryAll, state } from "lit/decorators.js";

const ANIMATION_STAGES = [
    {
        stage: 0,
        title: "Compare Fixed Rates",
        asset: "./assets/video/Scene_1_720.mp4",
        copy: "Illuminate finds all the best fixed-rate protocols for you, gathers their principal Tokens (PTs) and aggregates their yield into one place.",
    },
    {
        stage: 1,
        title: "GET Fixed Rates",
        asset: "./assets/video/Scene_2_720.mp4",
        copy: "For any given market, Illuminate offers the ideal combinations of PTs at the highest discounts, guaranteeing you the highest fixed rates in DeFi.",
    },
    {
        stage: 2,
        title: "WHOA Fixed Rates",
        asset: "./assets/video/Scene_3_720.mp4",
        copy: "They are minted at a 1:1 ratio into Illuminate PTs (iPTs), making them easy to integrate.",
    },
    {
        stage: 3,
        title: "WHOA Fixed Rates",
        asset: "./assets/video/Scene_4_720.mp4",
        copy: "Use Illuminate to lend your assets at the most competitive rates and earn the best yield on your crypto.",
    },
];

const template = function (this: AnimationHero) {
    return html`
        <section class="scroller">
            <div class="video-wrapper">
                <video
                    class="video"
                    autoplay=""
                    loop=""
                    muted=""
                    playsinline=""
                    class="promo-video"
                    preload="metadata"
                >
                    <source
                        src="./assets/video/Scene_1_720.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
            <div class="caption-wrapper">
                ${ANIMATION_STAGES.map((item, index) => {
                    return html`
                        <div class="caption" data-stage=${index}>
                            <h2>${item.copy}</h2>
                        </div>
                    `;
                })}
            </div>
        </section>
    `;
};

@customElement("sw-animation-hero-scrolling")
export class AnimationHero extends LitElement {
    stage: number | undefined = 0;

    @query(".video")
    protected video!: HTMLVideoElement;

    @queryAll(".caption")
    protected texts!: HTMLElement[];

    createRenderRoot() {
        return this;
    }

    firstUpdated() {
        this.watchForText();
    }

    protected updated(
        _changedProperties: Map<string | number | symbol, unknown>
    ): void {
        super.updated(_changedProperties);
    }

    watchForText() {
        const observer = new IntersectionObserver(
            this.intersectionCallback.bind(this),
            {
                threshold: 0.5,
            }
        );

        this.texts.forEach((text) => observer.observe(text));
    }

    intersectionCallback(entries: any) {
        let nextStage = this.stage;

        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                nextStage = entry.target.dataset.stage;

                entry.target.classList.add("visible");

                // this doesn't work on macOS thanks to the way how accelerated scroll is working (it overrides the scroll position after the fact...)
                // entry.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                if (entry.target.dataset.stage === nextStage) {
                    nextStage = undefined;
                }

                entry.target.classList.remove("visible");
            }
        });
        this.updateStage(nextStage);
    }

    updateStage(next: number | undefined) {
        if (next === this.stage) return;

        this.stage = next;

        this.updateVideo();
    }

    async updateVideo() {
        this.video.classList.remove("visible");

        await Promise.all(
            this.video.getAnimations().map((animation) => animation.finished)
        );

        if (this.stage) {
            const source = this.video.querySelector("source")!;
            source.src = ANIMATION_STAGES[this.stage].asset;
            this.video.load();
            await new Promise((resolve) => setTimeout(resolve, 400));
            this.video.classList.add("visible");
        } else {
            this.video.classList.remove("visible");
        }
    }

    render() {
        return template.apply(this);
    }
}
